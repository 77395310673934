.price-alert {

    // height: 81vh;
    .ant-card-body {
        height: 100%;

        .empty-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            .title {
                font-size: 22px;
                font-weight: 600;
                margin: 12px 0px;
            }

            .create-btn {
                width: 15%;
            }
        }
    }

    .price-alert-list {
        .search {
            .search-btn {
                margin-left: 12px;
            }
        }

        .table-list {
            margin-top: 16px;
        }
    }

    // .add-form {
    //     .radioBtn {
    //         background-color: transparent;
    //         // border: 1px solid #7A7A7A;
    //         // height: 48px
    //         border-radius: 16px;
    //         &:first-child {
    //             margin-right: 30px;
    //         }
    //         &:not(:first-child) {
    //             // display: none;
    //             &::before {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}