.tags {
    .tags-list {
        .header {
            margin-bottom: 16px;

            .search {
                .search-btn {

                    margin-left: 16px;
                }
            }
        }
    }
}