.group-mgmt {
    .header {
        margin-bottom: 22px;
        .search {
            .search-btn {
                margin-left: 16px;
            }
        }
    }
    // height: 81vh;
    // .ant-card-body {
    //     height: 100%;
    //     .empty-group {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         justify-content: center;
    //         height: 100%;
    //         .title {
    //             font-size: 22px;
    //             font-weight: 600;
    //             margin: 12px 0px;
    //         }
    //         .create-btn {
    //             width: 15%;
    //         }
    //     }
    //     .group-list {
    //         .header {
    //             margin-bottom: 12px;
    //         }
    //     }
    // }
}