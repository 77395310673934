.visualization {
    position: relative;
    .stats {
        margin-top: 16px;
        h2{
            font-size: 18px !important;
        }
        .turnover, .transaction {
            h2 {
                color: #3a2ce9 !important;

            }
        }
        .buy{
            h2{
                color: rgb(28, 198, 123) !important
            }
        }
        .sales {
            h2 {
                color: rgb(255, 137, 91) !important
            }
        }
    }

    .low-level-filter {
        margin-top: 16px;

        .filter-item {
            .label {
                margin-right: 12px;
                white-space: nowrap;
            }
        }
    }

    .total-transaction {
        margin-bottom: 24px;
    }

    .sector-wise {
        margin-top: 24px;
    }

    .round-chart {
        .doughnut-chart {
            height: 340px;
            width: 100%;

            .chart {
                margin: 0 auto;
            }


        }

        .legends {
            margin-top: 8px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
            justify-content: center;
            .legend-item {
                margin-bottom: 16px;
                cursor: pointer;
            }
        }
    }

    .visualization_pdf {
        position: absolute;
        top: 0;
        z-index: -9999;
        width: 100%;
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .filters {
            margin: 22px 0px;

            .date {
                .label {
                    margin-bottom: 0px;
                    margin-right: 12px;
                }
            }
        }
    }
}