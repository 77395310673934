.single-group-list {
    .header {}
}


.add-members-modal {
    .search-members {
        margin: 16px 0px;

        .ant-select {
            .ant-select-selector {
                min-height: 50px;
                height: auto !important;
            }
        }
    }

    .count {
        margin-bottom: 16px;

        .selected-count {
            .ant-checkbox-wrapper {
                margin-left: 12px;
            }
        }

        .delete-btn {
            height: auto;
        }

    }

    .members-list {
        height: 180px;
        overflow-y: auto;

        .members-card {
            .ant-card-body {
                padding: 8px 16px;
            }

            .ant-checkbox-wrapper {
                margin-right: 12px;
            }

            .name {
                font-size: 16px;
                font-weight: 600;
            }

            .id {
                font-size: 12px;
            }
        }
    }
}