@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.ant-layout-sider-collapsed {
	position: relative;
}
.collapse--switch {
	cursor: w-resize;
	&.position--fixed {
		position: absolute;
		right: -35px;
		cursor: e-resize;
	}
}

.ant-table {
	overflow-x: auto;
	.ant-table-row {
		td {
			white-space: nowrap;
		}
	}
}

.ant-input-search{
	input {
		padding-right: 33px;
	}
}

.ant-table-pagination {
	align-items: center;
}

.d-flex {
	display: flex;
}

.flex-direction-col {
	flex-direction: column;
}

.align-items-center {
	align-items: center;
}

.justify-between {
	justify-content: space-between;
}
.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: end;
}

.justify-start {
	justify-content: start;
}